import React from "react";

import { Box, Image, Stack } from "grommet";

const Logo = () => (
    <Stack anchor="center">
        <Box
                width="large"
                height="xxsmall"
                // round="small"
                align="center"
                justify="center"
        >
        <Image
            fit={"cover"}
            src="Veggit_transparent.png"
        />
        </Box>
        {/*<Box*/}
        {/*    width="xxsmall"*/}
        {/*    height="xxsmall"*/}
        {/*    round="small"*/}
        {/*    align="center"*/}
        {/*    justify="center"*/}
        {/*    border={{ color: "teal", size: "xlarge" }}*/}
        {/*/>*/}
        {/*<Box*/}
        {/*    width={"32px"}*/}
        {/*    height={"32px"}*/}
        {/*    round="small"*/}
        {/*    align="center"*/}
        {/*    justify="center"*/}
        {/*    background="white"*/}
        {/*/>*/}
    </Stack>
);

export default Logo;
