import axios from "axios";
import {API_ENDPOINT, MAPBOX_GEOCODE_API} from "config";



export async function updateUserAddress(UserID: string, Address: API.Address): Promise<any> {
    const params = {
        UserID: UserID,
        Address: Address
    }

    return axios.post(API_ENDPOINT  + '/user/address/update', params)
        .then(res => {
            return res
        })
}


/**
 * https://docs.mapbox.com/playground/geocoding/?search_text=02139&country=us&types=postcode%2Ccountry&access_token=pk.eyJ1IjoiZXBpcGh5dGUtamZhIiwiYSI6ImNsNGxtbDF4MjBqZ3MzZG12MmhmdGdkNjAifQ.WM0PDcw8340uK3KnfeFI3w
 * @param PostCode
 * @param Country
 */
export async function geoCoding(PostCode: string, Country: string): Promise<any> {

    const url = MAPBOX_GEOCODE_API + PostCode + ".json?country=" + Country + "&access_token=" + process.env.REACT_APP_MAPBOX_TOKEN

    return axios.get(url).then(res => {
        const geojson = res.data.features[0].geometry

        console.log(geojson)

        return geojson
    })

}





