import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
// @notus-pro/react
import {
    Avatar,
    Box,
    Button,
    DropButton,
    Menu
} from 'grommet'
// import Button from "@notus-pro/react/Button"
// import DropdownUser from "@notus-pro/react/DropdownUser"


type Props = {}

const Auth0LoginButton: React.FC<Props> = (props) => {
    const { loginWithPopup, logout, isAuthenticated, user } = useAuth0();

    // const buttonProps = {
    //     children: "button",
    //     color: "lightBlue",
    //     size: "lg",
    //     outline: false,
    // }

    if(isAuthenticated) {
        const { name, picture, email } = user;
        // const userWidgetConfig = {
        //     image: picture,
        //     items: [
        //         { href: "/dashboard", children: "My Produce" },
        //         { href: "/profile", children: "My Profile" },
        //         // { href: "#pablo", children: "Something else here" },
        //         { divider: true },
        //         { href: "#", children:  <div onClick={() => logout({ returnTo: window.location.origin })} {...buttonProps}>
        //                 Log Out
        //             </div> },
        //     ],
        // }
        return (
            <>


                    <Menu
                        // label="Fancy Selector"
                        items={[
                            // { label: 'First Action', onClick: () => {} },
                            { label: 'LogOut', onClick: () => {logout({ returnTo: window.location.origin })} },
                        ]}
                    >
                        <Avatar
                            src={picture}
                        />
                    </Menu>

            </>
        )
    } else {
        return (
            <Button primary label="Log In" onClick={() =>{console.log("ping"); loginWithPopup()}} />
        );
    }
};

export default Auth0LoginButton;
