import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {
    useAuth0,
    withAuthenticationRequired
} from "@auth0/auth0-react";
import {
    userExists,
    addUser
} from "API/API";

import {
    Box,
    Grommet
} from "grommet";
import {
    Grow,
    UserSettings,
    Search as SearchIcon,
    Chat
} from "grommet-icons";

import { theme } from "./theme";
import Sidebar from "./Components/Sidebar";
import LogoVertical from "Components/Logos/LogoVertical";

import Dashboard from "Pages/Dashboard"
import Landing from "Pages/Landing"
import Profile from "Pages/Profile"
import Search from "Pages/Search"
import Messages from "Pages/Messages"

function App() {

const ProtectedRoute = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        // onRedirecting: () => <Landing />,
    });

    return <Component />;
};

    const { logout, isAuthenticated, user } = useAuth0();



    if (isAuthenticated ) {

        userExists(user.sub).then(
            (exists) => {
                if (!exists) {
                    const profile = {
                        Name: user.given_name,
                        FamilyName: user.family_name,
                        Email: user.email
                    }
                    addUser(user.sub, profile)
                }
            })

        const userSession = {
            user: {
                name: user.name,
                thumbnail: user.picture
            },
            items: [
                {
                    label: "Logout",
                    onClick: () => {logout({ returnTo: window.location.origin })}
                }
            ]
        };

        const items = [
            {
                label: "Search",
                Icon: SearchIcon,
                path: "/search"
            },
            {
                label: "Produce",
                Icon: Grow,
                path: "/dashboard"
            },
            {
                label: "Profile",
                Icon: UserSettings,
                path: "/profile"
            },
            {
                label: "Messages",
                Icon: Chat,
                path: "/messages"
            }
        ];

        return (
            <BrowserRouter>
                <Grommet theme={theme} full>
                    <Box direction="row" fill>
                      <Sidebar
                        appIcon={<LogoVertical/>} //{<GrommetIcon color="brand"/>}
                        appName="VeggIt"
                        items={items}
                        userSession={userSession}
                      />
                      <Box flex>
                        <Routes>
                          <Route path="/" element={<Landing/>}/>
                          <Route path="/search" element={<Search/>}/>
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/dashboard" element={<Dashboard />}/>
                          <Route path="/messages" element={<Messages />}/>
                            {/*<Route component={NotFound} />*/}
                        </Routes>
                      </Box>
                    </Box>

                </Grommet>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <Grommet theme={theme} full>
                <Box flex>
                    <Routes>
                        <Route path="/" element={<Landing/>}/>
                        <Route element={<Landing/>}/>
                    </Routes>
                </Box>
            </Grommet>
        </BrowserRouter>
    )
}

export default App;
