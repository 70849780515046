import React from "react";
import { Anchor, Box, Text, ResponsiveContext } from "grommet";
import Logo from "./Logos/Logo";
import { SocialMedia } from "./SocialMedia";

const LandingHeader = () => (
    <ResponsiveContext.Consumer>
        {size => (
            <Box
                direction="row"
                justify="between"
                alignSelf="center"
                gap="medium"
                pad={{ top: "large", horizontal: "xlarge" }}
            >
            <Anchor
                href="/"
                icon={<Logo />}
                color="black"
                // label={
                //     size !== "xsmall" &&
                //     size !== "small" && <Text size="large">Vegg It!</Text>
                // }
            />
            <SocialMedia />
            </Box>
        )}
    </ResponsiveContext.Consumer>
);

export { LandingHeader };
