import axios from "axios";
import {API_ENDPOINT} from "config";

export async function searchProduce(lat: number, lon: number, distance: number): Promise<any> {

    const params = {
        Coordinates: [lat, lon],
        Distance: distance
    }

    return axios.post(API_ENDPOINT  + '/produce/search', params)
        .then(res => {
            return res.data.Payload
        })
}
