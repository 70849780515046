import React from "react";
// import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";

import { Box, Text } from "grommet";
import RoutedButton from "./RoutedButton";


import { MenuButton, UserMenu } from "./";

type Props = {
  appIcon: object,
  appName: string,
  items: Array<any>,
  userSession: any
}

const Sidebar: React.FC<Props> = (props) => {


    const { appIcon, appName, items = [], userSession, ...rest } = props;
    // const { router } = context;
    return (
      <Box
        fill="vertical"
        width="sidebar"
        background="dark-2"
        elevation="medium"
        {...rest}
      >
        <RoutedButton
          path="/"
          hoverIndicator="dark-4"
          // active={
          //   router &&
          //   !!matchPath(router.route.location.pathname, {
          //     path: "/",
          //     exact: true
          //   })
          // }
        >
          <Box
            flex={false}
            align="center"
            gap="xsmall"
            pad={{ vertical: "small" }}
          >
            {appIcon}
            {/*<Text size="xsmall">{appName}</Text>*/}
          </Box>
        </RoutedButton>
        <Box flex overflow="auto">
          {items.map(({ active, Icon, label, path }) => (
            <MenuButton
              active={active}
              Icon={Icon}
              path={path}
              label={label}
              key={label}
            />
          ))}
        </Box>
        {userSession && (
          <UserMenu
            alignSelf="center"
            user={userSession.user}
            items={userSession.items}
          />
        )}
      </Box>
    );

}

export default Sidebar
