import axios from "axios";
import {API_ENDPOINT} from "config";


export async function addProduceToUser(UserID: string, Produce: any): Promise<any> {

    const params = {
        UserID: UserID,
        Produce: Produce
    }

    return axios.post(API_ENDPOINT  + '/produce/add', params)
        .then(res => {
            return res
        })
}

export async function deleteProduce(ProduceID: string): Promise<any> {

    const params = {
        ProduceID: ProduceID
    }

    return axios.post(API_ENDPOINT  + '/produce/delete', params)
        .then(res => {
            return res
        })
}


export function userExists(UserID: string): Promise<any> {

    const params = {
        UserID: UserID
    }

    return axios.post(API_ENDPOINT  + '/user/exists', params)
        .then(res => {
            const exists: boolean = (res.data.Payload === 'true');

            return exists
        })
}



export async function addUser(UserID: string, Profile: any): Promise<any> {

    const params = {
        UserID: UserID,
        Profile: Profile
    }

    return axios.post(API_ENDPOINT  + '/user/add', params)
        .then(res => {
            return res
        })
}

export async function getUser(UserID: string): Promise<any> {
    const params = {
        UserID: UserID
    }

    return axios.post(API_ENDPOINT  + '/user/get', params)
        .then(res => {
            console.log(res)
            return res.data.Payload
        })
}

export async function updateUserProfile(UserID: string, Profile: API.Profile): Promise<any> {
    const params = {
        UserID: UserID,
        Profile: Profile
    }

    return axios.post(API_ENDPOINT  + '/user/profile/update', params)
        .then(res => {
            console.log(res)
            return res.data.Error
        })
}
