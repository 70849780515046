import axios from "axios";
import {STORAGE_ENDPOINT} from "config";

export async function uploadProduceImage(ImageData: string, ObjectKey: string, ContentType: string): Promise<any> {

    return axios.post(STORAGE_ENDPOINT + "/" + ObjectKey, ImageData, {
        headers: {
            'Content-Type': ContentType
        }
    }).then(res => {
            console.log(res)
            return res
    })

}
