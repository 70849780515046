import React from "react";
import { Anchor, Box } from "grommet";
import { FacebookOption, Instagram, Twitter } from "grommet-icons";
import Auth0LoginButton from 'Components/Auth0LoginButton'

const SocialMedia = () => (
    <Box direction="row" gap="xxsmall" justify="center">
        <Anchor
            target="_blank"
            a11yTitle="Instagram"
            href="https://instagram.com/veggit"
            icon={<Instagram color="brand" size="medium" />}
        />
        <Anchor
            target="_blank"
            a11yTitle="Chat with us on Slack"
            href="https://www.facebook.com/veggit"
            icon={<FacebookOption color="brand" size="medium" />}
        />
        <Anchor
            target="_blank"
            a11yTitle="Follow us on Twitter"
            href="https://twitter.com/veggit"
            icon={<Twitter color="brand" size="medium" />}
        />
            <Auth0LoginButton/>
    </Box>
);

export { SocialMedia };
