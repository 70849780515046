import React from "react";
// import PropTypes from "prop-types";
// import { matchPath } from "react-router-dom";
import { Button } from "grommet";
import { useNavigate } from "react-router";

type Props = {
  path: string
  // active: any
  hoverIndicator: string
}

// A simple component that shows the pathname of the current location
// class RoutedButton extends React.Component {
const RoutedButton: React.FC<Props> = (props) => {

  // static propTypes = {
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired,
  //   path: PropTypes.string.isRequired
  // };

  // onClick = event => {
  //   const { history, path } = this.props;
  //   event.preventDefault();
  //   history.push(path);
  // };
  const navigate = useNavigate();

    const {
      // active,
      // exact,
      // match,
      // location,
      // history,
      path,
      // strict,
      ...rest
    } = props;
    //
    // const pathMatch = matchPath(location.pathname, { exact, path, strict });

    return (
      // <Button active={active && !!pathMatch} {...rest} onClick={this.onClick} />
      <Button {...rest} onClick={() => {navigate(path)}}/>

    );

}

export default RoutedButton;
